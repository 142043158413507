import Keycloak from 'keycloak-js'

const KEYCLOAK_BASE_URL = process.env.REACT_APP_KEYCLOAK_URL;
const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM;
const KEYCLOAK_CLIENT = process.env.REACT_APP_KEYCLOAK_CLIENT;


export const keycloak = new Keycloak({
    url: KEYCLOAK_BASE_URL,
    realm: KEYCLOAK_REALM,
    clientId: KEYCLOAK_CLIENT
});

export const initOptions = {
    onLoad: 'login-required',
    pkceMethod: 'S256',
};

// TODO: Set Loading Component
export const loadingComponent = (
    <div>
        LOADING KEYCLOAK
    </div>
);
