import {Container, Nav} from "react-bootstrap";
import React, {Component} from "react";
import Navbar from "react-bootstrap/Navbar";
import {pluginsApi} from "../api/PluginApi";

class NavBar extends Component {

    constructor() {
        super();
        this.state = {
            plugins: []
        }
    }
    async componentDidMount() {

        try {
            const response = await pluginsApi.getPlugins();
            this.setState({
                plugins: response.data
            });
        } catch (error) {
            console.log(error)
        }
    }

    render() {

        return (
            <>
                <Navbar bg="dark" variant="dark">
                    <Container>
                        <Navbar.Brand href="/">Mapera</Navbar.Brand>
                        <Nav className="mr-auto">
                            {
                                this.state.plugins.map(p => (
                                    <Nav.Link key={p.id} href={'#' + p.id}>{p.name}<input type="hidden" id={"url" + p.id} value={p.url} /></Nav.Link>
                                ))
                            }
                        </Nav>
                        <Nav>
                            <Nav.Link href="/logout">Logout</Nav.Link>
                        </Nav>

                    </Container>
                </Navbar>
            </>
        );
    }
}

export default NavBar
