import axios from 'axios'

export const pluginsApi = {
    getPlugins,
    addPlugin,
    deletePlugin
};


function getPlugins() {
    return instance.get(`/api/plugins/all`);
}

function addPlugin(data, token) {
    return instance.post(`api/plugins/add`, data, {
        headers: { 'Authorization': `Bearer ${token}` }
    });
}

function deletePlugin(data, token) {
    return instance.post(`api/plugins/delete`, data, {
        headers: { 'Authorization': `Bearer ${token}` }
    });
}

// -- Axios

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
});

instance.interceptors.response.use(response => {
    return response;
}, function (error) {
    if (error.response.status === 404) {
        return { status: error.response.status };
    }
    return Promise.reject(error.response);
});
