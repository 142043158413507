import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Route } from 'react-router-dom'
import Loading from "./Loading";

function MaperaRoute({ component: Component, ...rest }) {
    const { keycloak } = useKeycloak();
    return (
        <Route
            {...rest}
            render={props => (
                keycloak?.authenticated ? <Component {...props} keycloak={keycloak} /> : <Loading />
            )}
        />
    )
}

export default MaperaRoute
