import NavBar from "./components/misc/Navbar";
import ContentPage from "./components/pages/ContentPage";
import {Component} from "react";
import {Col, Container, Row} from "react-bootstrap";
import Sidebar from "./components/pages/Sidebar";
import './App.css'

class App extends Component {

    render() {
        return (
            <div>
                <NavBar />
                <Container>
                    <Row className='row-content'>
                        <Col xs={9} className={"bg-light container-content"}>
                            <ContentPage keycloak={this.props.keycloak} />
                        </Col>
                        <Col className={"bg-light container-content"}>
                            <Sidebar keycloak={this.props.keycloak} />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

}

export default App;
