import React, { Component } from 'react'
import '../../App.css'

class Loading extends Component {

    render() {
        return (
            <p>Loading</p>
        )
    }
}

export default Loading
