import React, { Component } from 'react'
import {textApi} from "../api/TextApi";

class Home extends Component {

    constructor() {
        super();
        this.state = {
            sourceCode: ""
        }
    }


    async componentDidMount() {
        try {
            const response = await textApi.getText ("home");
            this.setState({
                sourceCode: response.data.text
            })
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        return (
            <div dangerouslySetInnerHTML={{__html: this.state.sourceCode}} />
        )
    }
}

export default Home
