import React from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import MaperaRoute from './components/misc/MaperaRoute'
import {initOptions, keycloak} from "./keycloak";
import Logout from "./components/pages/Logout";
import App from "./App";

function AppRouter() {

    return (
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={initOptions}
        >
            <Router>
                <Switch>
                    <MaperaRoute path="/logout" component={Logout} />
                    <MaperaRoute exact path="/" component={App} />
                </Switch>
            </Router>
        </ReactKeycloakProvider>
    )
}

export default AppRouter



