import axios from 'axios'

export const textApi = {
    getText,
    setText
};


function getText(id, token) {
    return instance.get(`/api/text`, {
        params: {'id': id}
    })
}

function setText(data, token) {
    return instance.post(`/api/text`, data,{
        headers: { 'Authorization': bearerAuth(token) }
    })
}

// -- Axios

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
});

instance.interceptors.response.use(response => {
    return response;
}, function (error) {
    if (error.response.status === 404) {
        return { status: error.response.status };
    }
    return Promise.reject(error.response);
});

// -- Helper functions

function bearerAuth(token) {
    return `Bearer ${token}`
}
