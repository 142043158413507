import React, {Component} from 'react'
import {pluginsApi} from "../api/PluginApi";
import {textApi} from "../api/TextApi";

class Admin extends Component {

    constructor() {
        super();
        this.state = {
            plugins: [],
            message: "",
            value: "",
            sidebar: "",
            home: "",
            error: "",
            used: ""
        }
        this.handlePluginChange = this.handlePluginChange.bind(this);
        this.handlePluginSave = this.handlePluginSave.bind(this);
        this.handlePluginDelete = this.handlePluginDelete.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextSave = this.handleTextSave.bind(this);
    }

    async componentDidMount() {

        try {
            const plugin = await pluginsApi.getPlugins();
            var p = plugin.data;
            p.push({
                name: "",
                id: "",
                url: "",
                parentId: "",
                priority: ""
            })

            const sidebar = await textApi.getText("sidebar");
            const home = await textApi.getText("home");

            this.setState({
                plugins: p,
                sidebar: sidebar.data.text,
                home: home.data.text
            });
        } catch (error) {
            console.log(error)
        }
    }

    handlePluginChange(event) {
        var p = this.state.plugins;
        p[event.target.id][event.target.name] = event.target.value;
        this.setState({plugins: p});
    }

    setErrorMessage(err) {
        this.setState({
            error: err
        })
    }

    pluginDataIsValid(id, data) {

        this.setErrorMessage("");

        if (data.id === "") {
            this.setErrorMessage("Plugin ID is not set");
            return false;
        }
        if (data.name === "") {
            this.setErrorMessage("Plugin name is not set");
            return false;
        }
        if (data.url === "") {
            this.setErrorMessage("Plugin url is not set");
            return false;
        }

        return true;
    }

    async handlePluginSave(event) {

        const id = event.target.id;
        const data = this.state.plugins[id];
        if(!this.pluginDataIsValid(id, data)) return;

        try {
            const response = await pluginsApi.addPlugin(data, this.props.token);
            this.setState({
                message: response.data
            })
        } catch (error) {
            console.log(error)
        }
        this.componentDidMount();
    }

    async handlePluginDelete(event) {

        const data = this.state.plugins[event.target.id];

        try {
            const response = await pluginsApi.deletePlugin(data, this.props.token);
            this.setState({
                message: response.data
            })
        } catch (error) {
            console.log(error)
        }
        this.componentDidMount();
    }

    handleTextChange(event) {
        this.setState({[event.target.id]: event.target.value});
    }

    async handleTextSave(event) {

        const data = {
            id: event.target.id,
            text: this.state[event.target.id]
        };

        try {
            const response = await textApi.setText(data, this.props.token);
            this.setState({
                message: response.data
            })
        } catch (error) {
            console.log(error)
        }
        this.componentDidMount();
    }

    render() {
        return (
            <div>
                <h1>Admin</h1>
                {
                    this.state.error === "" ? "" :
                        <div className="alert alert-danger" role="alert">
                            {this.state.error}
                        </div>

                }
                <h3>Plugins</h3>
                <div className={"table-responsive"}>
                    <table className={"table table-hover table-striped"}>
                        <thead className={"thead-dark"}>
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                ID
                            </th>
                            <th>
                                URL
                            </th>
                            <th>
                                Parent
                            </th>
                            <th>
                                Prio
                            </th>
                            <th>
                            </th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.plugins.map((p, i) => (
                                <tr>
                                    <td>
                                        <input id={i} name="name" className={"form-control"}
                                               value={this.state.plugins[i].name} onChange={this.handlePluginChange}/>
                                    </td>
                                    <td>
                                        <input id={i} name="id" className={"form-control"}
                                               value={this.state.plugins[i].id} onChange={this.handlePluginChange}/>
                                    </td>
                                    <td>
                                        <input id={i} name="url" className={"form-control"}
                                               value={this.state.plugins[i].url} onChange={this.handlePluginChange}/>
                                    </td>
                                    <td>
                                        <input id={i} name="parentId" className={"form-control"}
                                               value={this.state.plugins[i].parentId}
                                               onChange={this.handlePluginChange}/>
                                    </td>
                                    <td>
                                        <input id={i} name="priority" className={"form-control"}
                                               value={this.state.plugins[i].priority}
                                               onChange={this.handlePluginChange}/>
                                    </td>
                                    <td>
                                        <input id={i} value={"Speichern"} type="submit" className={"btn btn-success"}
                                               onClick={this.handlePluginSave}/>
                                    </td>
                                    <td>
                                        <input id={i} value={"Löschen"} type="submit" className={"btn btn-danger"}
                                               onClick={this.handlePluginDelete}/>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
                <h3>Text</h3>
                <div className={"table-responsive"}>
                    <table className={"table table-hover table-striped"}>
                        <thead className={"thead-dark"}>
                        <tr>
                            <th>
                                Region
                            </th>
                            <th>
                                Text
                            </th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                Sidebar
                            </td>
                            <td>
                                <textarea className={"form-control"} id={"sidebar"} value={this.state.sidebar} onChange={this.handleTextChange}/>
                            </td>
                            <td>
                                <input id={"sidebar"} value={"Speichern"} type="submit" className={"btn btn-success"} onClick={this.handleTextSave}/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Home
                            </td>
                            <td>
                                <textarea className={"form-control"} id={"home"} value={this.state.home} onChange={this.handleTextChange}/>
                            </td>
                            <td>
                                <input id={"home"} value={"Speichern"} type="submit" className={"btn btn-success"} onClick={this.handleTextSave}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default Admin
