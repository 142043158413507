import axios from 'axios'

export const externalPageApi = {
    getExternalPage
};

function getExternalPage(url, token) {
    return instance.get(url + "?auth=" + token)
}

// -- Axios

const instance = axios.create();

instance.interceptors.response.use(response => {
    return response;
}, function (error) {
    if (error.response.status === 404) {
        return { status: error.response.status };
    }
    return Promise.reject(error.response);
});
