import React, { Component } from 'react'

class Logout extends Component {

    logout() {
        this.props.history.push("/");
        this.props.keycloak.logout();
    }

    render() {
        return (
            <div>
                Logging out {this.logout()}
            </div>
        )
    }
}

export default Logout
