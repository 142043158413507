import React, { Component } from 'react'
import {externalPageApi} from "../api/ExternalPageApi";
import Home from "./Home";
import Admin from "./Admin";
import {keycloak} from "../../keycloak";

class ContentPage extends Component {

    constructor() {
        super();
        this.state = {
            sourceCode: "",
            hash: ""
        }
        this.handleHashChange = this.handleHashChange.bind(this);
    }


    componentDidMount() {
        window.addEventListener('hashchange', this.handleHashChange);
        this.handleHashChange();
    }

    async getContext(hash) {
        if (hash === "") return;
        //TODO Fix update - .then()?
        const { keycloak } = this.props;
        try {
            const response = await externalPageApi.getExternalPage (document.getElementById("url" + hash).value, keycloak.token);
            this.setState({
                sourceCode: response.data,
            })
        } catch (error) {
            console.log(error);
        }
    }


    handleHashChange() {
        const hash = window.location.hash.substring(1);
        this.setState({
            hash: hash
        })
        this.setState({
            sourceCode: "Loading"
        })
        this.getContext(hash);
    }

    render() {
        switch (this.state.hash) {
            case "":
                return (<Home />);
            case "admin":
                return (<Admin token={keycloak.token} />);
            default:
                return (<div dangerouslySetInnerHTML={{__html: this.state.sourceCode}} />);
        }
    }

}

export default ContentPage
